import React, {Component} from 'react'

import IcoFullstak from '@fullstakk/fms-frontend-asset/dist/img/fullstakk-logo-white-16.png'

/**
 * Account creation form.
 */
export default class AccountCreation extends Component {
    /**
     * Initializes the screen.
     *
     * @param Object props Properties of the screen
     */
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }

    /**
     * Renders the component.
     */
    render() {
        return (
            <React.Fragment>
                <form method='post' action='/app/auth' className='form-auth'>
                    <p className='text-center'>
                        <button type='submit' data-type='login-google' className='button' onClick={this.onClick}>
                            <i className='fab fa-google'></i> Sign in with Google
                        </button>
                    </p>
                </form>
                <form method='post' action='/app/auth' onSubmit={this.props.onSubmit} className='form-auth'>
                    <p className='text-center'>
                        <span className='text-separator'>Or</span>
                    </p>
                    <p className='text-center'>
                        <input type='text' name='email' id='email' placeholder='E-mail' required='required'/>
                    </p>
                    <p className='text-center'>
                        <input type='password' name='password' placeholder='Password' required='required'/>
                    </p>
                    <p className='text-center'>
                        <input type='text' name='firstname' placeholder='Firstname' required='required'/>
                    </p>
                    <p className='text-center'>
                        <input type='text' name='lastname' placeholder='Lastname' required='required'/>
                    </p>
                    <p className='text-center'>
                        <input type='text' name='company' placeholder='Company' required='required'/>
                    </p>
                    <p className='text-center'>
                        <input type='checkbox' id='privacy' name='privacy' value='1' required='required'/>
                        <label htmlFor='privacy'>I agree with <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a></label>
                    </p>
                    <p className='text-center'>
                        <button type='submit' data-type='login-password' className='button' onClick={this.onClick}>
                            <img src={IcoFullstak} width={16} height={13} alt='Fullstakk logo'/> Create account with my e-mail
                        </button>
                    </p>
                    <p className='text-center'>
                        <a href='/'>Sign in</a>
                    </p>
                </form>
            </React.Fragment>
        )
    }

    /**
     * Handles click on one submit entry.
     *
     * @param Event event Click event that has occurred
     */
    onClick(event) {
        //event.preventDefault()
        document.forms[0].id = event.target.dataset.type
        document.forms[1].id = event.target.dataset.type

        if (this.props.onClick) {
            return this.props.onClick(event)
        }

        return true
    }
}
