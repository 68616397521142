import React from 'react'
import {GfbSignInPassword} from '@fullstakk/fms-google-firebase'

import AccountCreation from '../components/Form/AccountCreation'

import Service from '../../lib/utility/Service'

import '../assets/css/layout/account-creation.scss'
import CfgFirebaseApp from '../../../conf/google/firebase.keys.json'

/**
 * Account creation screen.
 */
export default class ScreenAccountCreation extends React.Component {
    /**
     * Initializes the screen.
     *
     * @param Object props Properties of the screen
     */
    constructor(props) {
        super(props)
        this.onSignInWithEmail = this.onSignInWithEmail.bind(this)
        this.onFormSubmit = this.onFormSubmit.bind(this)
    }

    /**
     * Renders the main layout.
     */
    render() {
        const App = Service.instance('App')
        if (App.isBrowser()) {
            App.resetStorage()
        }

        return (
            <AccountCreation onClick={this.onSignInWithEmail} onSubmit={this.onFormSubmit}/>
        )
    }

    /**
     * Handles form submission.
     *
     * @param Event event Submission event that has occurred
     */
    onFormSubmit(event) {
        event.preventDefault()
        const App = Service.instance('App')
        const Auth = Service.instance('Auth')

        switch (event.target.id) {
            case 'login-password':
                Auth.signIn()

                // Create account with e-mail / password
                const auth = new GfbSignInPassword(CfgFirebaseApp)
                auth.create(document.forms[1].email.value, document.forms[1].password.value).then(async data => {
                    //this.consoleLogUser('Password', data)
                    await auth.database().ref('users/' + data.user.uid).set({
                        email: document.forms[1].email.value,
                        firstName: document.forms[1].firstname.value,
                        lastName: document.forms[1].lastname.value,
                        company: document.forms[1].company.value
                    })

                    App.redirect()
                }).catch(error =>
                    alert('Password error #' + error.code + '\n\n' + error.message)
                )
                break
            case 'login-google':
                Auth.signIn()
                Auth.signInGoogle(event)
                break
            default:
                break
        }

        return true
    }

    /**
     * Attempts to sign in the user with the entered e-ami address.
     *
     * @param Event event Submission event that has occurred
     */
    onSignInWithEmail(event) {
        const email = document.getElementById('email')
        if ((event.target.dataset.type === 'login-google') && email && email.value) {
            Service.instance('Auth').signIn(email.value)
        }
    }
}
