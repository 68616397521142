import React from 'react'

import Layout from '@fullstakk/fms-frontend-theme'

import Seo from '../lib/components/Application/Seo'
import Service from '../lib/utility/Service'

import Screen from '../app/screen/AccountCreation'

/**
 * Encapsulates the account creation page.
 */
export default class AccountCreation extends React.Component {
    /**
     * Renders the page.
     *
     * @returns {JSX.Element} Rendered page
     */
    render() {
        const App = Service.instance('App')
        return (
            <Layout
                theme='FullScreen'
                title={App.getName()}
                className='account-creation-wrapper'
                subTitle={(<span>By <a href="https://www.fullstakk.no/" rel="noopener noreferrer"
                target="_blank">Fullstakk Marketing</a></span>)}
            >
                <Seo title={'Account creation'} description={'Create your account to access to your marketing application.'} />
                <Screen/>
            </Layout>
        )
    }
}